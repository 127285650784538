main {
  max-width: 774px;
  margin: auto;
  text-align: left;
  padding: 1.5rem;
}

#film-container li {
  padding: 3px 0px;
}
/*  coding  */
#teams-list {
  list-style: katakana;
  padding: 0px 45px;
  padding-top: 10px;
}

#teams-list li {
  padding-bottom: 6px;
}

.photo {
  padding: 20px 0;
  border: 0px solid;
}

.style-border-btm {
  border-bottom: 1px solid;
  padding-bottom: 3px;
}

.nav-subheader {
  font-size:18px;
  font-weight:200;
  border-bottom: 0px solid;
}

.film-role {
  padding-left:20px;
  font-weight: bold;
}

#link-show-resume {
  display: block;
  max-width: 150px;
  font-weight: bold;
  border: 1px solid black;
  background: black;
  color: yellow;
  padding: 3px 14px;
  margin-top: 15px;
  margin-bottom: 5px;
}


@media only screen and (max-width: 768px) {
  main {
    padding: 1.5rem;
    text-align: left;
  }
  .photo {
    padding: 12.5px 0px;
  }
  .film-role {
    display: block;
    padding: 5px;
    text-decoration: underline;
  }
}
